body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*#root {*/
/*  padding-top: 68px;*/
/*}*/

.bg-primary {
  background-color: #be00b4 !important;
}

.btn-primary {
  background-color: #be00b4 !important;
  border: #be00b4 !important;
}

.bg-secondary {
  background-color: #108a10 !important;
}

.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.navbar-nav > .nav-item > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.5)
}

.text-secondary {
  color: #108a10 !important;
}