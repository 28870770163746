.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.table-dropdown {
    width: 150px !important;
    overflow-y: visible;
    overflow-x: visible;
}

.edit-popup {
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
    border-radius: 15px;
    border: #be00b4 1px solid;
    background-color: #ffddff;
}

.edit-popup > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.edit-popup > .content {
    width: 100%;
    padding: 10px 5px;
}
